export const fetchWrapper = {
    postForm,
}

function postForm(url, body) {
    const requestOptions = {
        method: 'POST',
        headers: {  },
        credentials: 'include',
        body: body
    };

    return fetch(url, requestOptions).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text //&& JSON.parse(text);

        console.log(text,response);

        if (!response.ok) {
            if ([401, 403].includes(response.status) ) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            }

            let error =  response.statusText ;

            if (data && data.errors) {
                error = data.errors[0]
            }

            return Promise.reject(text);
        }

        return data;
    });
}