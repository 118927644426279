import { Button } from 'react-bootstrap';
import { Form, Field, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import '../extensions/yup/extensions';
import ErrorMessageWithStyle from './errorMessageWithStyle';
import { useState } from 'react';

import "./contactForm.css";
import { LoadFileField } from './loadFileField';

import { Trash ,ArrowClockwise } from 'react-bootstrap-icons';
import ContactFormService from '../services/contactForm';

const ContactForm = ()=>{

    const maxMessageLength = 3000;

    const [sendResult, setSendResult] = useState("");
    const [response, setResponse] = useState("");
    


    const initialValues = {
        name:'',
        email:'',
        message:'',
        attachments:[],
        messageTo:'biuro'
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().max(30,"Maksymalna długość to 30.").required("Pole wymagane"),
        //email: Yup.string().isEmail("Błędny e-mail").required("Pole wymagane"),
        message: Yup.string().max(maxMessageLength,`Maksymalna długość to ${maxMessageLength}.`).required("Pole wymagane")
    });

    const onSubmit = async (fields,{setSubmitting})=>{
        
        try{
            await ContactFormService.sendForm(fields);
            setSendResult("success");
            setResponse("Wysłano e-mail");
        }
        catch(ex){
            console.log("error",ex);
            setSendResult("error");
            setResponse(ex);
        }

        setSubmitting(false)
    }

    const onRemoveAttachment = (idx)=>{

    }

    return(
        <>
        <Formik
            validateOnMount={true}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
        {({ errors, touched, isSubmitting, isValid, values ,setFieldValue}) => (
            <Form>
                <div className='container'>
                <div className='row pt-2 pb-2'>
                        <div className="form-group col-12 col-sm-6">
                            <label>Sprawa kierowana do:</label>
                            <Field as="select" name="messageTo" className='form-control'>
                                <option value="biuro">Biuro</option>
                                <option value="kadry">Kadry</option>
                            </Field>
                            <ErrorMessageWithStyle name="messageTo"/>
                        </div>
                    </div>
                    <div className='row pt-2 pb-2'>
                        <div className="form-group col-12 col-sm-6 ">
                            <label>Podaj imię i nawisko*</label>
                            <Field name="name" 
                            placeholder="Imię i nazwisko"
                            type="text"
                            aria-describedby="imię i nazwisko"
                            className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}
                            />
                            <ErrorMessageWithStyle name="name"/>
                        </div>
                        <div className="form-group col-12 col-sm-6">
                            <label>Adres email</label>
                            <Field name="email" 
                            placeholder="Podaj e-mail"
                            type="email"
                            aria-describedby="e-mail"
                            className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                            />
                            <ErrorMessageWithStyle name="email"/>
                        </div>
                    </div>
                    <div className='row pt-2 pb-2'>
                        <div className="form-group col-12">
                            <label>Treść wiadomości*</label>
                            <Field name="message" 
                            as="textarea"
                            aria-describedby="wiadomość"
                            className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')}
                            />
                            <ErrorMessageWithStyle name="message"/>
                            <small>
                               { maxMessageLength - values.message.length >= 0 ? "Pozostało znaków":"Przekroczono o "}
                               : { Math.abs(maxMessageLength - values.message.length) }
                            </small>
                        </div>
                    </div>
                    <div className='row pt-2 pb-2'>
                        <div className="form-group col-12">
                            <label>Załączniki</label>
                            <LoadFileField name="attachments" className="btn btn-success"/>
                            <div className='container'>
                                
                                    { values.attachments.map((e,i)=>{
                                        return(
                                        <div className='row pb-2 attachment'>
                                            <div className='col-10 col-sm-11'>{ e.name}</div>
                                            <div className='col-2 col-sm-1'>
                                                <button className='btn btn-danger' type="button" onClick={(i)=>{
                                                    var newatt = [...values.attachments];
                                                    newatt.splice(i,1);
                                                    setFieldValue('attachments',newatt);
                                                }}>
                                                    <Trash></Trash>
                                                </button>
                                            </div>
                                        </div>)
                                    })}
                                
                            </div>
                            
                        </div>
                    </div>
                    <div className="row p-5">
                        <div className="col-12 d-flex justify-content-center">
                            <button className="btn btn-danger" type="submit" disabled={!isValid || isSubmitting} >
                                Wyślij 
                                {isSubmitting?
                                    <ArrowClockwise className='ms-2 rotation' ></ArrowClockwise >
                                    :""
                                }
                            </button>
                        </div>
                    </div>
                    <div className={sendResult}>
                        {response}
                    </div>
                    <div className='requiredInfo'>
                        * Pola wymagane.    
                    </div>  
                </div>      
                    
                </Form>
        )}
        </Formik>
        
        </>
    );
}

export default ContactForm;