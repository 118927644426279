import logo from './logo.svg';
import './App.css';
import ContactForm from './components/contactForm';
import Layout from './components/layout';

function App() {
  return (
    <Layout>
        <ContactForm></ContactForm>
    </Layout>
  );
}

export default App;
