import * as Yup from 'yup';

function isEmail(message) {
    return this.test("isEmail", message, function (value) {
        const { path, createError } = this;


        let result = (value + "").match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        if (!result)
            return createError({ path, message: message ?? "ERROR: Wrong e-mail" });

        return true;
    });
}
Yup.addMethod(Yup.string, "isEmail", isEmail);