import "./layout.css";
import logo from "../assets/ppbr_logo_white.png";

const Layout =({children})=>{

    return (<>
        <header>
            <img src={logo}/>
            <div>
                <b>BIURO RACHUNKOWE PO PORSTU - SKRZYNKA NADAWCZA</b>
            </div>
        </header>
        <main>
            {children}
        </main>
        <footer>
            <ul>
                <li>
                    NIP: 5842746300
                </li>
                <li>
                    REGON: 36354661100000
                </li>
                <li>
                KRS: 0000597566
                </li>
                <li>
                © Biuro Rachunkowe Po Prosu { new Date().getFullYear() }
                </li>
            </ul>
        </footer>

    </>)

}

export default Layout;