import { fetchWrapper } from "./fetchWrapper";

const ContactFormService = {

    sendForm : async(fields)=>{
        var form = new FormData();

        for (let [key, value] of Object.entries(fields)) {
        
            if (value === "null") value = null;
        
            console.log(key,value)
            if(Array.isArray(value)){
                key = `${key}[]`;
                for(let el of value){
                    form.append(key, el);
                }
            }
            else  
                form.append(key, value);
        }
        
        return await fetchWrapper.postForm("./api/sendMail.php",form)
    }

}


export default ContactFormService;