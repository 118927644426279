import React, { useRef } from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';

function LoadFileField({ name, className }) {

    const input = useRef();

    const {
        values: { attachments },
        setFieldValue,
    } = useFormikContext();


    const onClick = () => {
        input.current.click();
        input.current.value = null;
    }

    const onChange = async (field, files) => {

        var newAttach = [...attachments];
        for(var file of files){
            newAttach.push(file);
        }
        
        setFieldValue(field, newAttach);
    }

    return (
        <>
            <div>
            Plik maksymalnie może mieć 10MB i mieć rozszerzenie jpg, jpeg, png, pdf, doc, docx, odt, txt, zip, xml, rtf.
            </div>
            <Field name={name}  >
                {({ field, form, meta }) => (
                    <div style={{ textAlign: 'center' }}>
                        <input
                            ref={input}
                            style={{ visibility: 'hidden', width: 0, heigh: 0, padding: 0 }}
                            type="file"
                            className={className + ' form-control ' + (form.errors[name] && form.touched[name] ? ' is-invalid' : '')}
                            onChange={(e) => {
                               onChange(field.name, e.target.files)
                            }} />
                        <button type="button" className={className} onClick={onClick}>
                            <span>
                                Dodaj załącznik
                            </span>
                        </button>
                    </div>
                )}
            </Field>
            <ErrorMessage name={name} component="div" className="invalid-feedback" />
        </>
    )
}

export { LoadFileField }