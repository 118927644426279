import { useFormikContext, ErrorMessage } from 'formik';

import "./errorMessageStyle.css";
const ErrorMessageWithStyle = ({name}) => {
    return (
        <div className="field_error">
            <ErrorMessage name={name} />
        </div>
    );
}

export default ErrorMessageWithStyle;